import {
  Vue, Options
} from 'vue-class-component'
import Header from '@/components/Header.vue'
import CurrencyInput from '@/components/CurrencyInput.vue'
import CurrencyInputDisable from '@/components/CurrencyInputDisable.vue'
import * as bootstrap from 'bootstrap'
import swal from 'sweetalert'
import { billFactory } from '@/http/bill'
import { masterDataFactory } from '@/http/master-data'
import { userFactory } from '@/http/user'

import Utils from '@/utils/utils'

@Options({
  components: {
    Header, CurrencyInput, CurrencyInputDisable
  },
  data() {
    return {
      students: [],
      selectedStudents: {},
      totalBill: 0,
      allSelectedMonth: false,
      monthIds: [],
      selectedClass: [],
      allSelectedClass: false,
      allSelectedClassCross: false,
      classIds: [],
      bills: [],
      school_years: [],
      bill_types: [],
      bill_detail_types: [],
      classes: [],
      months: [],
      crossClasses: [],
      spp_bill_id: null,
      data: {
        id: '',
        spp_bill_type_id: '',
        school_year_id: '',
        name: '',
        active_date: 1,
        deadline_date: '',
        due_date: '',
        default_total_amount: 0,
        months: [],
        classes: [],
        crossClasses: [],
        details: [],
        description: '',
        options: {},
        fine_rules: {
          value: 0,
          type: 'nominal',
          formula: 'flat'
        },
        user_updated: {
          name: ''
        },
        updated_at: ''
      },
      showModal: false,
      param: {
        school_year_id: 0
      },
      data_master: {
        school_years: "1",
        classes: "1",
        bill_types: "1",
        bill_detail_types: "1",
        months: "1",
        math_operations: "1"
      },
      classType: 1,
      myModal: [],
      loadingSave: false,
      loadingGenerate: false,
      searchField: ''
    }
  },
  methods: {
    save: function() {
      this.loadingSave = true
      var dataClasses = this.data.classes
      var dataCrossClasses = this.data.crossClasses
      if (this.data.crossClasses.length > 0) {
        var classes = dataClasses.concat(dataCrossClasses)
      } else {
        var classes = dataClasses
      }
      var data = {
        id: this.data.id,
        spp_bill_type_id: this.data.spp_bill_type_id,
        school_year_id: this.data.school_year_id,
        name: this.data.name,
        active_date: 1,
        deadline_date: this.data.deadline_date,
        due_date: this.data.due_date,
        default_total_amount: this.data.default_total_amount,
        months: this.data.months,
        classes: classes,
        details: this.data.details,
        description: this.data.description,
        fine_rules: {
          value: this.data.fine_rules.value,
          type: this.data.fine_rules.type,
          formula: this.data.fine_rules.formula
        }
      }
      if (this.data.id == '') {
        Promise.resolve(billFactory.createBill(data))
        .then(result => {
          if (result.error == false) {
            swal("Sukses menyimpan tagihan", "", "success")
            this.hideModal()
            this.loadData()
          } else if (result.response.data.error == true) {
            var errorMessage = result.response.data.messages
            var msg = ''
            for (let i in errorMessage) {
              msg += errorMessage[i] + "<br>"
            }
            var length = 100
            msg = msg.substring(0, length)
            swal({
              text: "Gagal Menyimpan tagihan",
              icon: "error",
              content: {
                element: "p",
                attributes: {
                  innerHTML: msg
                }
              }
            })
          }
        }).catch((e) => {
          swal("Gagal menyimpan tagihan", "", "error")
          console.log(e)
        }).finally(() => {
          this.loadingSave = false
        })
      } else {
        Promise.resolve(billFactory.updateBill(data.id, data))
        .then(result => {
          if (result.error == false) {
            swal("Sukses menyimpan tagihan", "", "success")
            this.hideModal()
            this.loadData()
          } else if (result.response.data.error == true) {
            var errorMessage = result.response.data.messages
            var msg = ''
            for (let i in errorMessage) {
              msg += errorMessage[i] + "<br>"
            }
            var length = 100
            msg = msg.substring(0, length)
            swal({
              text: "Gagal Menyimpan tagihan",
              icon: "error",
              content: {
                element: "p",
                attributes: {
                  innerHTML: msg
                }
              }
            })
          }
        }).catch((e) => {
          swal("Gagal menyimpan tagihan", "", "error")
          console.log(e)
        }).finally(() => {
          this.loadingSave = false
        })
      }
    },
    addDetail: function() {
      this.data.details.push({
        id: "",
        name: "",
        operation: "+",
        default_amount: 0
      })
    },
    removeDetail: function(i:number) {
      this.data.details.splice(i, 1)
    },
    checkAllMonth: function() {
      this.data.months = [];

      if (!this.allSelectedMonth) {
        for (let key in this.months) {
          this.data.months.push(key);
        }
      }
    },
    checkAllClass: function(type:any) {
      if (type == 'default') {
        this.data.classes = []
        if (!this.allSelectedClass) {
            this.classes.forEach((c:any) => {
              this.data.classes.push(c.class_id);
            })
        }
      } else {
        this.data.crossClasses = []
        if (!this.allSelectedClassCross) {
            this.crossClasses.forEach((c:any) => {
              this.data.crossClasses.push(c.class_id);
            })
        }
      }
    },
    calculatePrice: function() {
      this.data.default_total_amount = 0
      this.data.details.forEach((bill:any) => {
        if (bill.operation == '-') {
          this.data.default_total_amount -= parseInt(bill.default_amount)
        } else {
          this.data.default_total_amount += parseInt(bill.default_amount)
        }
      })
    },
    getMonthByID: function(id:any) {
      if (id != '') {
          return this.months[id].id
      }
    },
    edit: function(id: any) {
      Promise.resolve(billFactory.billDetail(id))
      .then(result => {
        if (result != false) {
          var bill = result.data.bill
          var data = {
            id: bill.id,
            spp_bill_type_id: bill.spp_bill_type_id,
            school_year_id: bill.school_year_id,
            name: bill.name,
            active_date: bill.active_date,
            deadline_date: bill.deadline_date,
            due_date: bill.due_date,
            default_total_amount: bill.default_total_amount,
            months: bill.months,
            details: bill.details,
            description: bill.description,
            options: bill.options,
            fine_rules: {
              value: bill.fine_rules.value,
              type: bill.fine_rules.type,
              formula: bill.fine_rules.formula
            },
            class_type: bill.class_type,
            user_updated: bill.user_updated,
            updated_at: Utils.beautifyDatetime(bill.updated_at)
          }
          this.data = data
          this.data.classes = []
          this.data.crossClasses = []
          var classes = this.classes.map(function(e:any) { return e.class_id.toString() })
          var crossClasses = this.crossClasses.map(function(e:any) { return e.class_id.toString() })
          bill.classes.forEach((c:any) => {
            if (crossClasses.indexOf(c) > -1) {
              this.data.crossClasses.push(c)
            } else if (classes.indexOf(c) > -1) {
              this.data.classes.push(c)
            }
          })
          this.allSelectedClass = false
          this.allSelectedMonth = false
          this.allSelectedClassCross = false
          this.classType = 1
          this.calculatePrice()
          // this.showModal()
        } else {
          swal("Gagal melakukan request", "", "error")
        }
      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    add: function() {
      // this.showModal()
      this.allSelectedClass = false
      this.allSelectedMonth = false
      this.allSelectedClassCross = false
      this.classType = 1
      this.data.id = ''
      this.data.spp_bill_type_id = ''
      this.data.school_year_id = ''
      this.data.name = ''
      this.data.active_date = 1
      this.data.deadline_date = ''
      this.data.due_date = ''
      this.data.default_total_amount = 0
      this.data.months = []
      this.data.classes = []
      this.data.crossClasses = []
      this.data.details = [{
        id: "",
        spp_bill_detail_type_id: null,
        operation: "+",
        default_amount: 0
      }]
      this.data.description = ''
      this.data.options = {}
      this.data.fine_rules = {
        value: 0,
        type: 'nominal',
        formula: 'flat'
      }
      this.data.class_type = ''
    },
    generate: function() {
      this.loadingGenerate = true
      swal({
        title: "Apakah anda yakin?",
        text: "Generate tagihan hanya akan menghasilkan tagihan ke siswa yang belum terbuat tagihannya, tagihan yang sudah ada tidak diduplikasi atau terubah datanya.",
        icon: "warning",
        buttons: {
          cancel: true,
          confirm: true
        },
        dangerMode: true,
      })
      .then((willGenerate) => {
        if (willGenerate) {
          if (this.data.id != '') {
            Promise.resolve(billFactory.generateBill(this.data.id))
            .then(result => {
              if (result.error == false) {
                var num_bill_user = result.data.num_bill_user
                swal("Sukses menghasilkan tagihan ke " + num_bill_user + " siswa", "", "success")
                this.hideModal()
                this.loadData()
              } else if (result.response.data.error == true) {
                var errorMessage = result.response.data.messages
                var msg = ''
                for (let i in errorMessage) {
                  msg += errorMessage[i] + "<br>"
                }
                var length = 100
                msg = msg.substring(0, length)
                swal({
                  text: "Gagal menghasilkan tagihan ke siswa",
                  icon: "error",
                  content: {
                    element: "p",
                    attributes: {
                      innerHTML: msg
                    }
                  }
                })
              }
            }).catch((e) => {
              swal("Gagal menghasilkan tagihan ke tiap siswa", "", "error")
              console.log(e)
            }).finally(() => {
              this.loadingGenerate = false
            })
          } else {
            swal("Gagal menghasilkan tagihan ke tiap siswa", "", "error")
            this.loadingGenerate = false
          }
        } else {
          swal("Batal generate tagihan!")
          this.loadingGenerate = false
        }
      })
    },
    generateByStudentIDs: function() {
      this.loadingGenerate = true
      swal({
        title: "Apakah anda yakin?",
        text: "Generate tagihan hanya akan menghasilkan tagihan ke siswa yang belum terbuat tagihannya, tagihan yang sudah ada tidak diduplikasi atau terubah datanya.",
        icon: "warning",
        buttons: {
          cancel: true,
          confirm: true
        },
        dangerMode: true,
      }).then((willGenerate) => {
        if (!willGenerate) {
          swal("Batal generate tagihan!")
          this.loadingGenerate = false
          return false
        }

        if (!this.spp_bill_id) {
          swal("Harap pilih tagihan terlebih dulu", "", "error")
          this.loadingGenerate = false
          return false
        }

        var student_ids = Object.keys(this.selectedStudents)
        if (student_ids.length < 1) {
          swal("Harap pilih dulu minimal satu siswa", "", "error")
          this.loadingGenerate = false
          return false
        }

        Promise.resolve(billFactory.generateBillByUserIds(this.spp_bill_id, student_ids))
          .then(result => {
            if (result.error == false) {
              var num_bill_user = result.data.num_bill_user
              swal("Sukses menghasilkan " + num_bill_user + " tagihan", "", "success")
              this.hideModal()
              this.loadData()
            } else if (result.response.data.error == true) {
              var errorMessage = result.response.data.messages
              var msg = ''
              for (let i in errorMessage) {
                msg += errorMessage[i] + "<br>"
              }
              var length = 100
              msg = msg.substring(0, length)
              swal({
                text: "Gagal menghasilkan tagihan ke siswa",
                icon: "error",
                content: {
                  element: "p",
                  attributes: {
                    innerHTML: msg
                  }
                }
              })
            }
          }).catch((e) => {
            swal("Gagal menghasilkan tagihan ke tiap siswa", "", "error")
            console.log(e)
          }).finally(() => {
            this.loadingGenerate = false
          })
      })
    },
    loadData: function() {
      Promise.resolve(billFactory.bill(this.param))
        .then(result => {
        this.bills = result.data.bills
      })
    },
    loadDataMaster: function() {
      Promise.resolve(masterDataFactory.master_datas(this.data_master))
      .then(result => {
        this.school_years = result.data.school_years
        var classes = result.data.classes
        var billTypes = result.data.bill_types
        billTypes.forEach((type:any,_:any,__:any) => {
          if (type.status == 1) {
            this.bill_types.push(type)
          }
        })
        this.bill_detail_types = result.data.bill_detail_types
        this.months = result.data.months
        this.math_operations = result.data.math_operations
        this.school_years.forEach((school_year:any) => {
          if (school_year.status == "Active") {
            this.param.school_year_id = school_year.school_year_id
          }
        })
        classes.forEach((c:any) => {
          if (c.class_type_id == 4) {
            this.crossClasses.push(c)
          } else {
            this.classes.push(c)
          }
        })
        this.loadData()
      })
    },
    showModal: function() {
      this.myModal.show()
    },
    hideModal: function() {
      this.myModal.hide()
    },
    setBillDetailType: function(value:String, i:any) {
      this.bill_detail_types.forEach((type: any) => {
        if (value == type.id) {
          this.data.details[i].operation = type.operation
          this.data.details[i].name = type.name
        }
      });
    },
    async getSuggestions(){
      if (this.searchField.length > 3) {
        var param = {
          "keyword": this.searchField
        }
        Promise.resolve(userFactory.students(param))
        .then(result => {
            var users = result.data.users[0]
            var students:any = []
            users.forEach((value:any) => {
              if (value.child_name == null) {
                students.push({
                  child_name: value.name,
                  child_user_id: value.user_id
                })
              } else {
                students.push(value)
              }
            })
            this.students = students
        }).catch((e) => {
          swal("Gagal melakukan request", "", "error")
          console.log(e)
          this.students = []
        })
      } else {
        this.students = []
      }
    },
    addToSelectedStudent (student:any) {
      this.selectedStudents[student.child_user_id] = student
    },
    removeSelectedStudent (student_id:number) {
      delete this.selectedStudents[student_id]
    }
  },
  computed: {
    config() {
      return {
        currency: 'IDR',
        precision: 0,
        distractionFree: false,
        valueAsInteger: true,
      }
    }
  },
  async mounted () {
    var myModal = document.getElementById('billModal')
    if (myModal != null) {
      this.myModal = new bootstrap.Modal(myModal)
    }

    await this.loadDataMaster()

  }
})

export default class Bill extends Vue {}
